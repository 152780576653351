import React from "react";
import comingSoonImage from "./comingsoon.jpg";

import "./App.css";

function App() {
  return (
    <div className="App">
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          zIndex: "-100",
          backgroundImage: `url(${comingSoonImage})`,
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "blur(8px) saturate(0.3)",
          WebkitFilter: "blur(8px)",
        }}
      />
      <img id="coming-soon-image" src={comingSoonImage} alt=""></img>
    </div>
  );
}

export default App;
